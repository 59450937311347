export default {
  name: "TopSlider",

  data() {
    return {
      index: null
    }
  },

  mounted() {
    this.setup();
  },

  methods: {
    setup() {
      this.$options.swiper = new Swiper(
        this.$refs.slider,
        {
          direction: "horizontal",
          loop: true,
          autoHeight: false,
          autoplay: {
            delay: 7500
          }
        }
      );

      this.$options.swiper.on("slideChange", this.onSlideChange);
      this.onSlideChange();
    },

    onSlideChange() {
      this.index = this.$options.swiper.realIndex;
    },

    next() {
      this.$options.swiper.slideNext();
    },

    prev() {
      this.$options.swiper.slidePrev();
    },

    goTo(i) {
      this.$options.swiper.slideToLoop(i);
    }
  }
};
