import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    api: {
      auth: "Basic YmVydGFpb2xhLmx1Y2FAZ21haWwuY29tOiRvdXRoYW1wdG9uIzE4ODUu"
    },

    headerBar: {
      isOpen: false
    },
    
    navigatorOverlay: {
      isOpen: false
    }
  },

  mutations: {
    headerBar(state, params) {
      if (_.isPlainObject(params) && _.isBoolean(params.isOpen)) {
        state.headerBar.isOpen = params.isOpen;
      }
    },
    navigatorOverlay(state, params) {
      if (_.isPlainObject(params) && _.isBoolean(params.isOpen)) {
        state.navigatorOverlay.isOpen = params.isOpen;
      }
    }
  }
})

export default store;
