export default {
  name: "NewsList",

  data() {
    return {
      isLoading: false,
      paginationEnd: false
    };
  },

  methods: {
    loadMore() {
      if (this.paginationEnd) {
        return;
      }
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.page++;
      const url = this.nextPageUrl.replace(/(\d+?)$/, this.page);

      fetch(url)
        .then(response => response.text())
        .then(html => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          const posts = doc.querySelectorAll(".article");
          const loadMore = doc.querySelector(".section__load-more");

          if (posts.length) {
            Array.from(posts).forEach(post => {
              this.$refs.posts.appendChild(post);
            });
          }

          if (!loadMore) {
            this.paginationEnd = true;
          }

          console.log(loadMore);

          this.isLoading = false;
        });
    }
  }
}