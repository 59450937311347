export default {
  name: "HeaderBar",

  data() {
    return {}
  },

  computed: {
    isOpen() {
      return this.$store.state.headerBar.isOpen;
    },

    navigatorOverlayIsOpen() {
      return this.$store.state.navigatorOverlay.isOpen;
    }
  },

  watch: {
    navigatorOverlayIsOpen(value) {
      if (value && this.isOpen) {
        this.$store.commit("headerBar", { isOpen: false });
      }
    }
  },

  methods: {
    toggleNav() {
      this.$store.commit("navigatorOverlay", { isOpen: !this.navigatorOverlayIsOpen });
    },

    toggleSearchBox() {
      this.$store.commit("headerBar", { isOpen: !this.isOpen });
    }
  }
};