import ics from "../ics/index.js";

export default {
  name: "MeetingsList",

  data() {
    return {
      calendarDate: null,
      isLoading: false,
      paginationEnd: false
    };
  },

  computed: {
    apiAuth() {
      return this.$store.state.api.auth;
    },

    calendarDateFormat() {
      const year = this.calendarDate.getFullYear();
      const month = `${this.calendarDate.getMonth() + 1}`;
      const day = `${this.calendarDate.getDate()}`;
      return `${year}-${month.length < 2 ? "0" : ""}${month}-${day.length < 2 ? "0" : ""}${day}`;
    },

    filterParam() {
      return this.currentFilter ? this.currentFilter.slug : null; 
    },

    today() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    }
  },

  watch: {
    calendarDate(value, valuePrev) {
      if (value < this.today) {
        this.calendarDate = this.today;
      }
      // ignore first param assign
      if (valuePrev) {
        this.dateSelect();
      }
    }
  },

  mounted() {
    this.calendarDate = new Date(this.calendarDateInit);
  },

  methods: {
    dateSelect() {      
      let url = `${this.baseUrl}/d:${this.calendarDateFormat}`;
      if (this.filterParam) {
        url = `${url}/f:${this.filterParam}`;
      }
      location.href = url;
    },

    filter(filterSlug) {
      location.href = `${this.baseUrl}/f:${filterSlug}/d:${this.calendarDateFormat}`;
    },

    loadMore() {
      if (this.paginationEnd) {
        return;
      }
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.page++;
      const url = this.nextPageUrl.replace(/(\d+?)$/, this.page);

      fetch(url)
        .then(response => response.text())
        .then(html => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          const boxes = doc.querySelectorAll(".meetings-list__box");
          const loadMore = doc.querySelector(".meetings-list__load-more");

          // calendar download
          Array
            .from(boxes)
            .forEach(el => {
              const events = el.querySelectorAll(".meetings-list__event");
              
              Array
                .from(events)
                .forEach(el => {
                  const id = el.getAttribute("data-id");
                  const downloadButton = el.querySelector(".meetings-list__button");
                  if (id && downloadButton) {
                    downloadButton.addEventListener("click", () => {
                      this.downloadIcs(id);
                    });
                  }
                })
            });

          if (boxes.length) {
            Array.from(boxes).forEach(el => {
              this.$refs.events.appendChild(el);
            });
          }

          if (!loadMore) {
            this.paginationEnd = true;
          }

          this.isLoading = false;
        });
    },

    downloadIcs(id) {
      const url = `${window.__baseUrl}/api/pages/${id.replace(/\//g, "+")}`;
      const headers = new Headers();
      headers.append("Authorization", this.apiAuth);

      fetch(url, {
          method: 'GET',
          headers
        })
        .then(response => response.json())
        .then(data => {
          if (data && data.data && data.data.content) {
            const eventData = data.data.content;
            const date = new Date(eventData.date);
            const time = eventData.time.split(":");
            const duration = { days: 0, hours: 1, minutes: 0 };

            date.setHours(parseInt(time[0]));
            date.setMinutes(parseInt(time[1]));

            if (eventData.timeend) {
              let hours, minutes;
              const timeEnd = eventData.timeend.split(":");
              const dateEnd = new Date(eventData.date);
              
              dateEnd.setHours(parseInt(timeEnd[0]));
              dateEnd.setMinutes(parseInt(timeEnd[1]));
              let milliseconds = dateEnd - date;
              hours = Math.floor(((milliseconds / 1000) / 60) / 60);
              milliseconds -= hours * 60 * 60 * 1000;
              minutes = (milliseconds / 1000) / 60;
              
              duration.hours = hours;
              duration.minutes = minutes;
            }

            const meeting = {
              start: [
                date.getFullYear(),
                date.getMonth() + 1,
                date.getDate(),
                date.getHours(),
                date.getMinutes()
              ],
              duration,
              title: eventData.title,
              description: eventData.description,
              location: eventData.location
            };
            
            ics.createEvent(meeting, (error, value) => {
              if (error) {
                console.log(error);
                return;
              }
              window.location = `data:text/calendar;charset=utf8,${escape(value)}`;
            });
          }
        });
    }
  }
}
