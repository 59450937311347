import Gallery from "./gallery.js";
import HeaderBar from "./header-bar.js";
import ImageSlider from "./image-slider.js";
import Map from "./map.js";
import MeetingsList from "./meetings-list.js";
import NavigatorOverlay from "./navigator-overlay.js";
import NewsList from "./news-list.js";
import Persons from "./persons.js";
import Podcast from "./podcast.js";
// import SliderTop from "./slider-top.js";
import TopSlider from "./top-slider.js";

export default {
  Gallery,
  HeaderBar,
  ImageSlider,
  Map,
  MeetingsList,
  NavigatorOverlay,
  NewsList,
  Persons,
  Podcast,
  TopSlider
  // SliderTop
};
