export default {
  name: "ImageSlider",

  mounted() {
    this.setup();
  },

  methods: {
    setup() {
      this.$options.swiper = new Swiper(
        this.$el,
        {
          direction: "horizontal",
          loop: true,
          autoHeight: false
        }
      );

      this.$options.swiper.on("slideChange", this.onSlideChange);
      this.onSlideChange();
    },

    onSlideChange(e) {
      const index = this.$options.swiper.realIndex;
      Array
        .from(document.querySelectorAll(".d-map-gallerycaption"))
        .forEach(el => {
          if (el.classList.contains(`d-map-gallerycaption--${index}`)) {
            el.classList.add("d-map-gallerycaption--active");
          } else {
            el.classList.remove("d-map-gallerycaption--active");
          }
        });
    },

    next() {
      this.$options.swiper.slideNext();
    },

    prev() {
      this.$options.swiper.slidePrev();
    }
  }
};
