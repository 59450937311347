export default {
  name: "Map",

  data() {
    return {
      index: null,
      indexChild: null,
      positions: [
        null,
        // 1
        {
          left: 54,
          top: 49,
          scale: 5,
          children: [
            null,
            {
              left: 40,
              top: 50
            }
          ]
        },
        // 2
        {
          left: 38,
          top: 32,
          scale: 2.25,
          children: [
            null,
            {
              left: 46,
              top: 50
            },
            {
              left: 47,
              top: 87
            },
            {
              left: 57,
              top: 52
            }
          ]
        },
        // 3
        {
          left: 20,
          top: 49,
          scale: 3,
          children: [
            null,
            {
              left: 55,
              top: 30
            },
            {
              left: 75,
              top: 63
            },
            {
              left: 55,
              top: 55
            },
            {
              left: 70,
              top: 54
            }
          ]
        },
        // 4
        {
          left: 48,
          top: 38,
          scale: 3,
          children: [
            null,
            {
              left: 52,
              top: 35
            },
            {
              left: 35,
              top: 60
            },
            {
              left: 55,
              top: 63
            },
            {
              left: 48,
              top: 55
            }
          ]
        },
        // 5
        {
          left: 44,
          top: 51,
          scale: 3,
          children: [
            null,
            {
              left: 43,
              top: 40
            },
            {
              left: 43,
              top: 52
            },
            {
              left: 50,
              top: 35
            },
            {
              left: 50,
              top: 58
            }
          ]
        },
        // 6
        {
          top: 60,
          left: 44,
          scale: 3,
          children: [
            null,
            {
              left: 65,
              top: 53
            },
            {
              left: 30,
              top: 45
            },
            {
              left: 45,
              top: 50
            },
            {
              left: 60,
              top: 40
            }
          ]
        },
        // 7
        {
          top: 55,
          left: 55,
          scale: 3,
          children: [
            null,
            {
              left: 52,
              top: 50
            },
            {
              left: 34,
              top: 44
            },
            {
              left: 43,
              top: 47
            },
            {
              left: 43,
              top: 59
            }
          ]
        },
        // 8
        {
          top: 70,
          left: 55,
          scale: 3,
          children: [
            null,
            {
              left: 40,
              top: 40
            },
            {
              left: 60,
              top: 63
            }
          ]
        },
        // 9
        {
          top: 72,
          left: 75,
          scale: 3,
          children: [
            null,
            {
              left: 35,
              top: 45
            },
            {
              left: 55,
              top: 50
            },
            {
              left: 55,
              top: 70
            }
          ]
        },
        // 10
        {
          top: 70,
          left: 63,
          scale: 3,
          children: [
            null,
            {
              left: 45,
              top: 40
            },
            {
              left: 47,
              top: 30
            },
            {
              left: 61,
              top: 63
            },
            {
              left: 45,
              top: 55
            }
          ]
        },
        // 11
        {
          top: 48,
          left: 67,
          scale: 2.5,
          children: [
            null,
            {
              left: 45,
              top: 20
            },
            {
              left: 55,
              top: 53
            },
            {
              left: 49,
              top: 67
            },
            {
              left: 38,
              top: 35
            },
            {
              left: 40,
              top: 75
            },
            {
              left: 55,
              top: 83
            },
            {
              left: 33,
              top: 58
            }
          ]
        },
        // 12
        {
          top: 31,
          left: 57,
          scale: 3,
          children: [
            null,
            {
              left: 45,
              top: 45
            },
            {
              left: 52,
              top: 57
            },
            {
              left: 39,
              top: 80
            }
          ]
        },
        // 13
        {
          top: 45,
          left: 58,
          scale: 3,
          children: [
            null,
            {
              left: 38,
              top: 60
            },
            {
              left: 40,
              top: 50
            },
            {
              left: 48,
              top: 50
            }
          ]
        },
        // 14
        {
          top: 47,
          left: 52,
          scale: 3,
          children: [
            null,
            {
              left: 44,
              top: 55
            },
            {
              left: 42,
              top: 58
            },
            {
              left: 50,
              top: 46
            }
          ]
        }
      ]
    };
  },

  computed: {
    currentArea() {
      if (this.type === "map" && this.index === null) {
        return;
      }
      
      if (this.type === "vicariate" && this.indexChild === null) {
        return;
      }

      if (this.type === "map" && this.areas[this.index]) {
        return this.areas[this.index];
      }

      if (this.type === "vicariate" && this.areas[this.indexChild]) {
        return this.areas[this.indexChild];
      }

      return null;
    },

    // only for vicariate template
    mapStyle() {
      const i = this.index;
      if (this.type === "vicariate" && this.positions[i]) {
        const left = this.positions[i].left;
        const top = this.positions[i].top;

        return {
          transformOrigin: `${left}% ${top}%`,
          transform: `translate(${50 - left}%, ${50 - top}%) scale(${this.positions[i].scale})`
        };
      }
      return {};
    },

    tooltipActive() {
      return this.tooltipStyle && this.tooltipStyle.top && this.tooltipStyle.left;
    },

    tooltipLabel() {
      if (this.currentArea) {
        return this.currentArea.name;
      }
      return "";
    },

    tooltipStyle() {   
      if (this.index === null) {
        return;
      }

      if (!this.positions[this.index]) {
        return {};
      }
      
      if (this.type === "map") {
        return {
          top: `${this.positions[this.index].top}%`,
          left: `${this.positions[this.index].left}%`
        };
      }

      if (this.type === "vicariate" && this.indexChild !== null && this.positions[this.index].children[this.indexChild]) {
        return {
          top: `${this.positions[this.index].children[this.indexChild].top}%`,
          left: `${this.positions[this.index].children[this.indexChild].left}%`
        };
      }

      return {};
    }
  },

  methods: {
    areaSelect(i) {
      if (this.type === "map") {
        this.index = i;
      }
      if (this.type === "vicariate") {
        this.indexChild = i;
      }
    },

    goToArea(i) {
      window.location.href = this.areas[i].url;
    }
  }
};
