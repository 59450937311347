export default {
  name: 'Podcast',

  data() {
    return {
      filtersActive: [],
      tags: [],
      podcasts: [],
      pageIndex: 0,
      limit: 25
    };
  },

  computed: {
    podcatsListFiltered() {
      return this.podcasts.filter(podcast => {
        if (this.filtersActive.length) {
          const podcastTags = podcast.tags.map(tag => tag.slug)
          // podcast contains every tag (and logic)
          return this.filtersActive.reduce((value, slug) => {
            if (!value) {
              return false
            } else {
              return podcastTags.includes(slug)
            }
          }, true)
        }
        else {
          return true          
        }
      })
    },

    podcatsList() {
      return this.podcatsListFiltered.filter((item, i) => {
        return (i >= (this.limit * this.pageIndex)) && (i < (this.limit * (this.pageIndex + 1)))
      })
    },

    pages() {
      return Math.ceil(this.podcatsListFiltered.length / this.limit)
    }
  },

  mounted() {
    const id = this.$el.getAttribute('data-id');
    if (id && window[`__${id}`]) {
      const data = window[`__${id}`]
      if (data.tags) {
        this.tags = data.tags
      }
      if (data.podcasts) {
        this.podcasts = data.podcasts
      }
    }
  },

  methods: {
    toggleFilter(slug) {
      if (this.filtersActive.includes(slug)) {
        this.filtersActive.splice(this.filtersActive.indexOf(slug), 1)
      } else {
        this.filtersActive.push(slug)
      }
    }
  }
}
