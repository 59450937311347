import Vue from "vue";
import ElementUI from 'element-ui';
import * as components from "./base/components.js";
import lang from 'element-ui/lib/locale/lang/it';
import locale from 'element-ui/lib/locale';

import '../scss/main.scss'

locale.use(lang);
Vue.use(ElementUI);

lang.el.datepicker.month1 = "Gennaio";
lang.el.datepicker.month2 = "Febbraio";
lang.el.datepicker.month3 = "Marzo";
lang.el.datepicker.month4 = "Aprile";
lang.el.datepicker.month5 = "Maggio";
lang.el.datepicker.month6 = "Giugno";
lang.el.datepicker.month7 = "Luglio";
lang.el.datepicker.month8 = "Agosto";
lang.el.datepicker.month9 = "Settembre";
lang.el.datepicker.month10 = "Ottobre";
lang.el.datepicker.month11 = "Novembre";
lang.el.datepicker.month12 = "Dicembre";
lang.el.datepicker.prevMonth = "Precedente";
lang.el.datepicker.nextMonth = "Successivo";

Promise.resolve() 
  .then(() => {
    if (["interactive", "complete"].includes(document.readyState)) {
      return Promise.resolve();
    } else {
      return new Promise(resolve => {
        const onReadyStateChange = function (event) {
          if (["interactive", "complete"].includes(event.target.readyState)) {
            document.removeEventListener("readystatechange", onReadyStateChange);
            resolve();
          }
        };
        document.addEventListener("readystatechange", onReadyStateChange);
      });
    }
  })
  .then(() => {
    components.init();
  });

  window.$emitter = new Vue();
