export default {
  name: "Gallery",

  data() {
    return {
      images: []
    }
  },

  mounted () {
    this.$el.querySelectorAll('img').forEach(img => {
      this.images.push({
        src: img.getAttribute('data-image-url'),
        w: img.getAttribute('data-image-width'),
        h: img.getAttribute('data-image-height')
      })
    })
  },

  methods: {
    toggleLightbox(e) {
      let target = e.target
      while(target.tagName !== 'LI') {
        target = target.parentNode
      }
      const index = Array.from(this.$el.querySelectorAll('li')).indexOf(target)
      const gallery = new PhotoSwipe(
        document.querySelector(".pswp"),
        PhotoSwipeUI_Default,
        this.images,
        {
          index,
          history: false
        }
      );
      gallery.init();
    }
  }
}
