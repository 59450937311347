export default {
  name: 'Persons',

  data() {
    return {
      letter: 'a',
      letters: [],
      persons: []
    };
  },

  computed: {
    personsByLetter() {
      return this.persons.filter(person => person.letter === this.letter);
    }
  },

  mounted() {
    if (window.__persons) {
      this.letters = window.__persons.letters
      this.persons = window.__persons.persons
      this.letter = this.letters[0]
    }
  },

  methods: {
    setLetter(value) {
      this.letter = value
    }
  }
}
