export default {
  name: "NavigatorOverlay",

  data() {
    return {};
  },

  computed: {
    headerBarIsOpen() {
      return this.$store.state.headerBar.isOpen;
    },

    isOpen() {
      return this.$store.state.navigatorOverlay.isOpen;
    }
  },

  watch: {
    headerBarIsOpen(value) {
      if (value && this.isOpen) {
        this.$store.commit("navigatorOverlay", { isOpen: false });
      }
    },

    isOpen() {
      this.setUnscrollableBody();
    }
  },

  methods: {
    setUnscrollableBody() {
      if (this.isOpen) {
        window.document.body.classList.add("unscrollable");
      } else {
        window.document.body.classList.remove("unscrollable");
      }
    },

    toggleNav() {
      this.$store.commit("navigatorOverlay", { isOpen: !this.isOpen });
    }
  }
};